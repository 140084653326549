var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      class: _vm.className,
      attrs: {
        width: "52",
        height: "50",
        viewBox: "0 0 52 50",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M32.8885 0L36.0216 3.03166C34.7252 4.28614 34.7252 6.3172 36.0216 7.57168C37.318 8.82617 39.417 8.82617 40.7135 7.57168L43.8466 10.6033C42.5501 11.8578 42.5501 13.9038 43.8466 15.1583C45.143 16.4128 47.242 16.4128 48.5539 15.1583L51.6715 18.19L45.3591 24.2981L26.5761 6.10812L32.8885 0V0ZM43.4761 26.1201L18.7974 50L15.6644 46.9833C16.9608 45.7139 16.9608 43.6828 15.6644 42.4283C14.3679 41.1738 12.2535 41.1738 10.957 42.4283L7.82398 39.3967C9.12042 38.1422 9.12042 36.1111 7.82398 34.8566C6.52754 33.6022 4.42853 33.6022 3.13209 34.8566L-0.000976562 31.825L24.6932 7.93011L43.4761 26.1201Z",
          fill: "#E7775A",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }