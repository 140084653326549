<template>
    <div class="boxsetResume" :class="'boxset-'+band">
        <SvgBoxsetIcon :className="'boxsetIcon forceFill-'+band+' '" />
        <strong class="boxsetName">Salabam {{focus | capitalize}}</strong>
        <div :class="'salaBadge forceBackground-'+band+' boxsetBackground'">{{band | uppercase}}</div>
        <div class="salaBadge nights">{{nights}} <span v-if="nights > 1">notti</span><span v-else>notte</span></div>
        
        <!-- https://trello.com/c/J5421i8L/841-customizations-nascondere-prezzo-in-prenotazione -->
        <strong v-if="price !== undefined && !bookingHidePrice && !mixinGetIntegrationInfo().isGiftCard && this.$route.name !== 'reservations'">
            {{price}} €
        </strong>

        <p class="expire" v-if="expireDate !== undefined">
            <SvgInfoCircle v-b-popover.hover.top="'Potrai fare la prenotazione in qualsiasi momento entro questa data ma la data di scadenza indica anche la data entro la quale il viaggio deve essere effettuato.'" /> Valido fino al {{expireDate | formatDate}}
        </p>
    </div>
</template>

<script>
import SvgBoxsetIcon from './../../svg/boxset-icon'
import SvgInfoCircle from './../../svg/info-circle'

export default {
    name: 'boxset-resume',
    components:
    {
      //Spinner,
      SvgBoxsetIcon, SvgInfoCircle
    },
    props:
    {
        focus: String,
        band: String,
        nights: Number,
        price: Number,
        expireDate: Date,
        bookingHidePrice: {
          default: false,
          type: Boolean,
        }
    },
    methods:
    {
    },
    mounted() {
        /*
        if(process.env.VUE_APP_MODE != 'production')
        {
            window.console.log(this.expireDate)
        }
        */
    }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';

    .boxsetResume
    {
        display:flex;
        align-items: center;
        flex-wrap:wrap;
        &>*
        {
            margin-right:12px;
        }
        .boxsetIcon
        {
            height:auto;
            width:30px;
            transform:translateY(-2px);
        }
        strong
        {
            font-size: 22px;
            margin-bottom:0;
        }
        .salaBadge
        {
            font-size: 18px;
            margin-bottom:0;
            margin-top:0;
        }
        .expire
        {
            //margin-top:0.25rem;
            flex:100%;
            min-width: 100%;
            margin-bottom:0;
            display:flex;
            align-items: center;
            font-size: 14px;
            svg
            {
                margin-right:0.5rem;
                width:16px;
            }
        }
        .salaBadge.nights
        {
            background:transparent;
            color:$black;//$dark-blue;
            padding:0;
        }
    }
</style>