var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "boxsetResume", class: "boxset-" + _vm.band },
    [
      _c("SvgBoxsetIcon", {
        attrs: { className: "boxsetIcon forceFill-" + _vm.band + " " },
      }),
      _c("strong", { staticClass: "boxsetName" }, [
        _vm._v("Salabam " + _vm._s(_vm._f("capitalize")(_vm.focus))),
      ]),
      _c(
        "div",
        {
          class: "salaBadge forceBackground-" + _vm.band + " boxsetBackground",
        },
        [_vm._v(_vm._s(_vm._f("uppercase")(_vm.band)))]
      ),
      _c("div", { staticClass: "salaBadge nights" }, [
        _vm._v(_vm._s(_vm.nights) + " "),
        _vm.nights > 1
          ? _c("span", [_vm._v("notti")])
          : _c("span", [_vm._v("notte")]),
      ]),
      _vm.price !== undefined &&
      !_vm.bookingHidePrice &&
      !_vm.mixinGetIntegrationInfo().isGiftCard &&
      this.$route.name !== "reservations"
        ? _c("strong", [_vm._v("\n        " + _vm._s(_vm.price) + " €\n    ")])
        : _vm._e(),
      _vm.expireDate !== undefined
        ? _c(
            "p",
            { staticClass: "expire" },
            [
              _c("SvgInfoCircle", {
                directives: [
                  {
                    name: "b-popover",
                    rawName: "v-b-popover.hover.top",
                    value:
                      "Potrai fare la prenotazione in qualsiasi momento entro questa data ma la data di scadenza indica anche la data entro la quale il viaggio deve essere effettuato.",
                    expression:
                      "'Potrai fare la prenotazione in qualsiasi momento entro questa data ma la data di scadenza indica anche la data entro la quale il viaggio deve essere effettuato.'",
                    modifiers: { hover: true, top: true },
                  },
                ],
              }),
              _vm._v(
                " Valido fino al " +
                  _vm._s(_vm._f("formatDate")(_vm.expireDate)) +
                  "\n    "
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }